import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/images/search.png";
import downloadIcon from "../../assets/images/download.png";
import editIcon from "../../assets/images/editIcon.png";
import laptop from "../../assets/images/laptop.jpg";
import logo from "../../assets/images/2k-logo.png";
import close from "../../assets/images/close.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import PopupModal from "../popupmodel/PopupModel";
import UserPic from "../../assets/images/userpic.png";
import phone from "../../assets/images/phone.png";
import deleteIcon from "../../assets/images/delete.png";
import shareIcon from "../../assets/images/shareIcon.png";
import PopUpBox from "../popupmodel/PopUpBox";
import { useQuery } from "react-query";
import axios from "axios";

const ViewUser = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [completePopup, setCompletePopup] = useState(false);
  const [trackPopup, setTrackPopup] = useState(false);
  const navigate = useNavigate();

  const getViewUser = async () => {
    const response = await axios.get(
      `https://api.2kvirtualworld.com/CRM/registers`
    );
    return response.data;
  };

  const tableData = [
    {
      userId: "2k001",
      firstName: "sabari",
      lastName: "raja",
      mobileNo: 7010489435,
      altMobileNo: 701048934,
      companyName: "2K",
      companyAddress: "chennai",
      pincode: 636121,
      companyType: "Company Type",
      role: "limited admin",
      emailId: "sabari@gmail.com",
      password: "sabari123",
      confirmPassword: "sabari@123",
    },
    {
      userId: "2k001",
      firstName: "sabari",
      lastName: "raja",
      mobileNo: 7010489435,
      altMobileNo: 701048934,
      companyName: "2K",
      companyAddress: "chennai",
      pincode: 636121,
      companyType: "Company Type",
      role: "limited admin",
      emailId: "raja@gmail.com",
    },
  ];
  const { data, isLoading, isError } = useQuery("getViewUser", getViewUser);
  let details = data && data;
  console.log("details", details);
  return (
    <>
      <div className="overview-main-block my-4">
        <div className="overview-heading-block">
          <h6 className="overview-head">VIEW USER</h6>
        </div>
        <div className="table-responsive overview-table-block">
          <table className="table">
            <thead className="table-heading">
              <tr>
                <th className="view-user-table-head">User ID</th>
                <th className="view-user-table-head">First Name</th>
                <th className="view-user-table-head">Last Name</th>
                <th className="view-user-table-head">Mobile No</th>
                <th className="view-user-table-head">Alternate No</th>
                <th className="view-user-table-head">Company Name</th>
                <th className="view-user-table-head">Company Address</th>
                <th className="view-user-table-head">Pincode</th>
                <th className="view-user-table-head">Company Type</th>
                <th className="view-user-table-head">Role</th>
                <th className="view-user-table-head">Email ID</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr style={{ height: "140PX", position: "relative" }}>
                  <div class="wrapper">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="shadow"></div>
                    <div class="shadow"></div>
                    <div class="shadow"></div>
                    <span>Loading</span>
                  </div>
                </tr>
              ) : isError ? (
                <tr>
                  <p
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: 800,
                    }}
                  >
                    No Network...
                  </p>
                </tr>
              ) : details && details?.length > 0 ? (
                details?.map((item, i) => {
                  return (
                    <>
                      <tr
                        key={i}
                        onClick={() => {
                          navigate("/newUser", { state: item });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="view-user-table-data">{item?.userId}</td>
                        <td className="view-user-table-data">
                          {item?.firstName}
                        </td>
                        <td className="view-user-table-data">
                          {item?.lastName}
                        </td>
                        <td className="view-user-table-data">
                          {item?.mobileNumber}
                        </td>
                        <td className="view-user-table-data">
                          {item?.altMobileNumber}
                        </td>
                        <td className="view-user-table-data">
                          {item?.companyName}
                        </td>
                        <td className="view-user-table-data">
                          {item?.companyAddress}
                        </td>
                        <td className="view-user-table-data">
                          {item?.pincode}
                        </td>
                        <td className="view-user-table-data">
                          {item?.companyType}
                        </td>
                        <td className="view-user-table-data">{item?.role}</td>
                        <td className="view-user-table-data">
                          {item?.emailId}
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <>
                  <p className="px-2">No Records Founds</p>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewUser;
