import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function SideBar() {
  const navigate = useNavigate();
  return (
    <div className="" style={{ backgroundColor: "" }}>
      <Sidebar>
        <Menu
          className="pt-3 m-0"
          style={{ backgroundColor: "#4068F3", height: "100vh" }}
        >
          <div className="ps-2">
            {" "}
            <img
              className=""
              style={{ height: "60px", width: "60px" }}
              src="/asset/logo.png"
              alt="companyLogo"
            />{" "}
            <span className="fs-5 fw-bold ps-2 text-light">2K SERVICE</span>{" "}
          </div>
          <MenuItem className="sidebar_menuItem" component={<Link to="/" />}>
            {" "}
            Overview{" "}
          </MenuItem>
          {/* <SubMenu className="sidebar_menuItem" label="View Request">
            <MenuItem className="sidebar_subMainMenu"> New Request </MenuItem>
            <MenuItem className="sidebar_subMainMenu"> pending </MenuItem>
            <MenuItem className="sidebar_subMainMenu"> completed </MenuItem>
            <MenuItem className="sidebar_subMainMenu"> Cancelled </MenuItem>
          </SubMenu> */}
          <SubMenu className="sidebar_menuItem" label="Technicians">
            <MenuItem
              className="sidebar_subMainMenu"
              component={<Link to="/viewTechnicians" />}
            >
              {" "}
              All Technician{" "}
            </MenuItem>
            <MenuItem
              className="sidebar_subMainMenu"
              component={<Link to="/newTechnician" />}
            >
              {" "}
              New Technician{" "}
            </MenuItem>
          </SubMenu>
          <MenuItem
            className="sidebar_menuItem"
            component={<Link to="/addRequest" />}
          >
            {" "}
            Add Request{" "}
          </MenuItem>
          <MenuItem className="sidebar_menuItem"> Add Product </MenuItem>
          <SubMenu className="sidebar_menuItem" label="User Management">
            <MenuItem
              className="sidebar_subMainMenu"
              component={<Link to="/newUser" />}
            >
              {" "}
              New User{" "}
            </MenuItem>
            <MenuItem
              className="sidebar_subMainMenu"
              component={<Link to="/viewUserManagement" />}
            >
              {" "}
              View User{" "}
            </MenuItem>
          </SubMenu>
          <MenuItem
            className="sidebar_menuItem"
            onClick={() => {
              sessionStorage.removeItem("login");
              navigate("/");
              window.location.reload();
            }}
          >
            {" "}
            Logout{" "}
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
}
