import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/images/search.png";
import downloadIcon from "../../assets/images/download.png";
import laptop from "../../assets/images/laptop.jpg";
import logo from "../../assets/images/2k-logo.png";
import close from "../../assets/images/close.png";
import editIcon from "../../assets/images/editIcon.png";
import sandtimer from "../../assets/images/sandtimer.png";
import cancelIcon from "../../assets/images/Cancel.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import UserPic from "../../assets/images/userpic.png";
import phone from "../../assets/images/phone.png";
import deleteIcon from "../../assets/images/delete.png";
import PopUpBox from "../popupmodel/PopUpBox";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import DeletePopup from "../popupmodel/DeletePopup";
import Popup from "../popupmodel/Popup";

const getViewTechnicians = async () => {
  const response = await axios.get(
    "https://api.2kvirtualworld.com/CRM/technicians"
  );
  return response.data;
};

const deleteItem = async (id) => {
  await axios.delete(`https://api.2kvirtualworld.com/CRM/technicians/${id}`);
};
const ViewTechnician = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [popupState, setPopupState] = useState({
    isOpen: false,
    dltpopup: false,
    dltmsgpopup: false,
    type: null,
    data: null,
  });
  const [technicianId, setTechnicianId] = useState("");

  // Fetch technicians data
  const { data, refetch, isLoading, isError } = useQuery(
    "getViewTechnicians",
    getViewTechnicians
  );

  // Mutation to handle technician deletion
  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      refetch();
      setPopupState({ isOpen: true, type: "success", data: null });
    },
    onError: (err) => {
      console.error("Error deleting technician:", err);
    },
  });

  // Effect to apply filter and search term
  useEffect(() => {
    if (data) {
      const applyFilterAndSearch = () => {
        const result = data.filter((item) => {
          const matchesFilter = filter ? item.status === filter : true;
          const matchesSearch = searchTerm
            ? (item.employeeId ?? "")
                .toString()
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase()) ||
              (item.firstName ?? "")
                .toString()
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase()) ||
              (item.mobileNo ?? "")
                .toString()
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase())
            : true;
          return matchesFilter && matchesSearch;
        });

        setFilteredData(result);
      };

      applyFilterAndSearch();
    }
  }, [data, filter, searchTerm]);

  // Open the details popup
  const handleOpenDetailsPopup = (item) => {
    setPopupState({ isOpen: true, type: "details", data: item });
    setTechnicianId(item.id);
  };

  // Open delete confirmation popup
  const handleDeleteClick = () => {
    setPopupState({
      isOpen: true,
      type: "deleteConfirmation",
      data: null,
      dltpopup: true,
      dltmsgpopup: false,
    });
  };

  // Confirm the deletion
  const handleDeleteConfirmation = () => {
    if (technicianId) {
      mutation.mutate(technicianId);
      setPopupState({ isOpen: false, type: "success", data: null });
    }
  };

  // Close all popups
  const handleCloseAllPopups = () => {
    setPopupState({ isOpen: false, type: null, data: null });
  };

  // Function to handle Excel download
  const downloadExcel = () => {
    // Determine data to export based on filter
    const exportData = filteredData?.length > 0 ? filteredData : data;

    // Prepare data for the table worksheet
    const tableDatas = exportData.map((data, index) => ({
      "S.NO": index + 1,
      "EMPLOYEE ID": data?.employeeId || "NULL",
      "EMPLOYEE NAME": `${data?.firstName} ${data?.lastName}` || "NULL",
      ADDRESS: data?.address || "NULL",
      "MOBILE NUMBER": data?.mobileNo || "NULL",
      DEPARTMENT: data?.department || "NULL",
      ROLE: data?.role || "NULL",
      "COUNT OF REQUEST": data?.countOfRequest || "NULL",
      STATUS: data?.status || "NULL",
    }));

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(tableDatas, {
      header: [
        "EMPLOYEE ID",
        "EMPLOYEE NAME",
        "ADDRESS",
        "MOBILE NUMBER",
        "DEPARTMENT",
        "ROLE",
        "COUNT OF REQUEST",
        "STATUS",
      ],
      skipHeader: false, // Ensure the header is included
    });

    // Create summary data
    const summaryData = [
      {
        Label: "EMPLOYEE ID",
        Value: exportData.map((data) => data?.employeeId).join(", ") || "NULL",
      },
      {
        Label: "EMPLOYEE NAME",
        Value:
          exportData
            .map((data) => `${data?.firstName} ${data?.lastName}`)
            .join(", ") || "NULL",
      },
      {
        Label: "ADDRESS",
        Value: exportData.map((data) => data?.address).join(", ") || "NULL",
      },
      {
        Label: "MOBILE NUMBER",
        Value: exportData.map((data) => data?.mobileNo).join(", ") || "NULL",
      },
      {
        Label: "DEPARTMENT",
        Value: exportData.map((data) => data?.department).join(", ") || "NULL",
      },
      {
        Label: "ROLE",
        Value: exportData.map((data) => data?.role).join(", ") || "NULL",
      },
      {
        Label: "COUNT OF REQUEST",
        Value:
          exportData.map((data) => data?.countOfRequest).join(", ") || "NULL",
      },
      {
        Label: "STATUS",
        Value: exportData.map((data) => data?.status).join(", ") || "NULL",
      },
    ];

    // Create summary worksheet with headers
    const summaryWorksheet = XLSX.utils.json_to_sheet(summaryData, {
      header: ["Label", "Value"],
      skipHeader: false,
    });

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");
    XLSX.utils.book_append_sheet(workbook, summaryWorksheet, "Summary Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "All Technicians.xlsx");
  };

  return (
    <>
      <div className="overview-main-block my-4">
        <div className="overview-heading-block">
          <h6 className="overview-head">ALL TECHNICIANS</h6>
          <div className="overview-search-block">
            <select
              className="overview-statusbar"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            >
              <option value="">Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
            <div
              className="overview-download-block"
              onClick={() => downloadExcel()}
            >
              <div className="search-icon">
                <img src={downloadIcon} alt="no-image" className="crm-image" />
              </div>
              <button className="overview-btn">DOWNLOAD</button>
            </div>
            <div className="d-flex align-items-center search-block">
              <input
                type="search"
                className="table-search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="search-icon">
                <img src={searchIcon} alt="no-image" className="crm-image" />
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive overview-table-block">
          <table className="table overview-table">
            <thead className="table-heading">
              <tr>
                <th className="technicians-table-head">Employee ID</th>
                <th className="technicians-table-head">Employee Name</th>
                <th className="technicians-table-head">Address</th>
                <th className="technicians-table-head">Mobile No</th>
                <th className="technicians-table-head">Department</th>
                <th className="technicians-table-head">Role</th>
                <th className="technicians-table-head">Count Of Request</th>
                <th className="technicians-table-head">Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr style={{ height: "140PX", position: "relative" }}>
                  <div class="wrapper">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="shadow"></div>
                    <div class="shadow"></div>
                    <div class="shadow"></div>
                    <span>Loading</span>
                  </div>
                </tr>
              ) : isError ? (
                <tr>
                  <p
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: 800,
                    }}
                  >
                    No Network...
                  </p>
                </tr>
              ) : filteredData && filteredData?.length > 0 ? (
                filteredData?.map((item, i) => {
                  // console.log("filteredData", filteredData);
                  return (
                    <>
                      <tr
                        key={item.id}
                        onClick={() => handleOpenDetailsPopup(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="technicians-table-data">
                          {item?.employeeId}
                        </td>
                        <td className="technicians-table-data">
                          {item?.firstName} {item?.lastName}
                        </td>
                        <td className="technicians-table-data">
                          {item?.address}
                        </td>
                        <td className="technicians-table-data">
                          {item?.mobileNo}
                        </td>
                        <td className="technicians-table-data">
                          {item?.department}
                        </td>
                        <td className="technicians-table-data">{item?.role}</td>
                        <td className="technicians-table-data">
                          {item?.countOfRequest}
                        </td>
                        <td className="technicians-table-data">
                          {item?.status}
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <>
                  <p className="px-2">No Records Founds</p>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Popup
        isOpen={popupState.isOpen && popupState.type === "details"}
        onClose={handleCloseAllPopups}
        backgroundColor="#f9f9f9"
        maxWidth="500px"
      >
        {popupState?.data && (
          <div className="popup-box">
            <div className="tech-details">
              <div className="popup-heading-block">
                <div className="popup-logo-block">
                  <img src={logo} alt="no-image" className="crm-image" />
                </div>
                <span className="popup-logo-heading">2K SERVICE</span>
              </div>
              <div className="popup-profile-block">
                <div className="popup-profile">
                  <img src={UserPic} alt="no-image" className="crm-image" />
                </div>
              </div>
              <p className="tech-user-details">
                TECHNICIAN NAME : {popupState?.data?.firstName}{" "}
                {popupState?.data?.lastName}
              </p>
              <p className="tech-user-details">TECHNICIAN ID : 2KSV001</p>
              <p className="tech-user-details">
                MOBILE NUMBER : {popupState?.data?.mobileNo}
              </p>
              <p className="tech-user-details">
                EMAIl : {popupState?.data?.emailId}
              </p>
            </div>
            <div className="request-details">
              <div className="request-user-details mt-5">
                <p className="request-key">TECHNICIAN ID</p>{" "}
                <p className="request-value">: 2K001</p>
              </div>
              <div className="request-user-details">
                <p className="request-key">DEPARTMENT</p>{" "}
                <p className="request-value">
                  : {popupState?.data?.department}
                </p>
              </div>
              <div className="request-user-details">
                <p className="request-key">ROLE </p>{" "}
                <p className="request-value"> : {popupState?.data?.role} </p>
              </div>
              <div className="request-user-details">
                <p className="request-key">COUNT OF REQUEST </p>{" "}
                <p className="request-value"> : 51</p>
              </div>
              <div className="request-user-details">
                <p className="request-key">COMPLETED REQUEST</p>{" "}
                <p className="request-value">: 51</p>
              </div>
              <div className="request-user-details">
                <p className="request-key">STATUS </p>{" "}
                <p className="request-value">: ACTIVE</p>
              </div>
              <div className="mt-4  mb-2 d-flex justify-content-center gap-2">
                <button className="popup-btn">ACTIVE</button>

                <div className="popup-delete" onClick={handleDeleteClick}>
                  <img src={deleteIcon} alt="no-image" className="crm-image" />
                </div>
                <div
                  className="popup-edit"
                  onClick={() => {
                    navigate("/newTechnician",{state:popupState?.data});
                  }}
                >
                  <img src={editIcon} alt="no-image" className="crm-image" />
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
      <Popup
        isOpen={popupState.dltpopup && popupState.type === "deleteConfirmation"}
        onClose={handleCloseAllPopups}
        backgroundColor="#004bcb"
        maxWidth="350px"
        onConfirm={handleDeleteConfirmation}
      >
        <div className="delete-popup-block">
          <p className="delete-content">Are You Sure</p>
          <div className="d-flex gap-2 my-2">
            <button
              className="delete-popup-btn"
              onClick={() => {
                handleDeleteConfirmation();
              }}
            >
              OK
            </button>
            <button
              className="delete-popup-btn"
              onClick={() => {
                handleCloseAllPopups();
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Popup>
      <Popup
        isOpen={popupState.isOpen && popupState.type === "success"}
        onClose={handleCloseAllPopups}
        maxWidth="350px"
        backgroundColor="#d4edda"
      >
        <div className="delete-sub-popup px-3 py-2">
          <p className="delete-sub-popup-content">
            Technician ID has been Deleted
          </p>
          <img src={sandtimer} />
          <button
            className="delete-sub-popup-btn"
            onClick={() => {
              handleCloseAllPopups();
            }}
          >
            Done
          </button>
        </div>
      </Popup>
    </>
  );
};

export default ViewTechnician;
