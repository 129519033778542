import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "../common/components/inputfield/InputField";
import { useMutation } from "react-query";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router";

export default function NewUser() {
  const { control, handleSubmit, setValue, reset, watch } = useForm();
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log("state", state);

  useEffect(() => {
    if (state) {
      setValue("firstName", state?.firstName);
      setValue("lastName", state?.lastName);
      setValue("mobileNumber", state?.mobileNumber);
      setValue("altMobileNumber", state?.altMobileNumber);
      setValue("companyAddress", state?.companyAddress);
      setValue("companyName", state?.companyName);
      setValue("companyType", state?.companyType);
      setValue("pincode", state?.pincode);
      setValue("role", state?.role);
      setValue("emailId", state?.emailId);
      setValue("password", state?.password);
      setValue("confirmpassword", state?.confirmpassword);
    }
  }, []);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/registers`,
          postData
        );
        alert("successfully added.");
        navigate("/viewUserManagement")
        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);

        throw error;
      }
    }
  );

  const UpdateFormValue = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/registers/${state?.id}`,
        postData
      ),
    {
      onSuccess: (data) => {
        alert("successfully updated.");
        navigate("/viewUserManagement");
      },
      onError: (error) => {
        // alert(error)
        console("Mutation failed:", error);
      },
    }
  );

  //   const UpdateFormValue = useMutation(
  //     async (postData) => {
  //         try {
  //           console.log("postData", postData);
  //           const response = await axios.put(
  //             `${process.env.REACT_APP_HOST}/registers${state?.id}`,
  //             postData
  //           );
  //           alert("successfully updated.");
  //           Navigate("/viewUserManagement")
  //           return response.data;
  //         } catch (error) {
  //           console.error("Error in mutation:", error);

  //           throw error;
  //         }
  //       }
  //   )
  const { password, confirmpassword } = watch();
  const Submit = (data) => {
    console.log("data", data);
    const formValue = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobileNumber: data?.mobileNumber,
      altMobileNumber: data?.altMobileNumber,
      companyAddress: data?.companyAddress,
      companyName: data?.companyName,
      companyType: data?.companyType,
      pincode: data?.pincode,
      role: data?.role,
      emailId: data?.emailId,
      password: data?.password,
      confirmpassword: data?.confirmpassword,
      // status: "Active",
    };
    if (state && state?.id) {
      UpdateFormValue.mutate(formValue);
    } else if (password === confirmpassword) {
      mutate(formValue);
    }
  };
  return (
    <div className="w-100 p-3 px-4 ">
      <div
        className="row bg-white px-3 pb-4"
        style={{ height: "95vh", borderRadius: "10px",overflowY:"scroll" }}
      >
        <header
          className="fs-2 fw-bold p-2 ps-2 mb-2 "
          style={{ height: "10%" }}
        >
          NEW USER
        </header>
        <section>
          <form onSubmit={handleSubmit(Submit)}>
            <div className=" col-12 mb-3  d-flex ">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">First Name</label>
                <Controller
                  control={control}
                  name="firstName"
                  render={(field) => (
                    <InputField
                      addUserInputCss="--addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Last Name</label>
                <Controller
                  control={control}
                  name="lastName"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss="--addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className="col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className=" col-5  addUser_ticket_label">
                  Mobile No
                </label>

                <Controller
                  control={control}
                  name="mobileNumber"
                  render={(field) => (
                    <InputField
                      addUserInputCss="--addUser_inputFields"
                      {...field}
                      type="number"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className=" col-5  addUser_ticket_label">
                  Alternate No
                </label>

                <Controller
                  control={control}
                  name="altMobileNumber"
                  render={(field) => (
                    <InputField
                      addUserInputCss="--addUser_inputFields"
                      {...field}
                      type="number"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Company Name
                </label>
                <Controller
                  control={control}
                  name="companyName"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" col-9 --addUser_companyName_inputField"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="d-flex">
                <label
                  style={{ width: "20%" }}
                  className="addUser_ticket_label"
                >
                  Company Address
                </label>
                <Controller
                  control={control}
                  name="companyAddress"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" col-9 --addUser_companyName_inputField"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">
                  Company Type
                </label>
                <Controller
                  control={control}
                  name="companyType"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="text"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Pin Code</label>
                <Controller
                  control={control}
                  name="pincode"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="number"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            {/* <div className="col-12 mb-4"></div> */}

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Role</label>
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <select
                      value={value}
                      onChange={onChange}
                      className="--addUser_inputFields"
                      style={{ "text-transform": "capitalize" }}
                    >
                      <option> </option>
                      <option value="admin">Admin</option>
                      <option value="limitedAdmin">Limited Admin</option>
                      <option value="superAdmin">Super Admin</option>
                    </select>
                  )}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">E Mail</label>
                <Controller
                  control={control}
                  name="emailId"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="email"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">Password</label>
                <Controller
                  control={control}
                  name="password"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="password"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className=" col-12 mb-3 d-flex">
              <div className="col-6 ">
                <label className="col-5 addUser_ticket_label">
                  Confirm Password
                </label>
                <Controller
                  control={control}
                  name="confirmpassword"
                  render={(field) => (
                    <InputField
                      // style = {{margin}}
                      addUserInputCss=" --addUser_inputFields"
                      {...field}
                      type="password"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>

            <div className="col-12 addUser_submitBtn_field ">
              <button className=" col-5 addUser_submitBtn" type="submit">
                {" "}
                SUBMIT
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}
