import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginScreen from "../../screen/loginscreen/LoginScreen";
import OverviewScreen from "../../screen/overview/OverviewScreen";
import { QueryClient, QueryClientProvider } from "react-query";
import SideBar from "../../component/SideBar";
import NewTechnician from "../../screen/NewTechnician";
import ViewTechnicians from "../../screen/techniciansscreen/ViewTechnicians";
import ViewUserManagement from "../../screen/usermanagementscreen/ViewUserManagement";
import AddRequest from "../../screen/AddRequest";
import NewUser from "../../screen/NewUser";

const Navigation = () => {
  const queryClient = new QueryClient();
  const loginTrue = sessionStorage.getItem("login");
  // const loginValue = true;
  return (
    <>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {loginTrue ? (
            <div className="d-flex" style={{ backgroundColor: "#4068F3",height:"auto"}}>
            <SideBar/>
              <Routes>
                <Route path="/" element={<OverviewScreen />} />
                <Route path="/newTechnician" element={<NewTechnician />} />
                <Route path="/addRequest" element={<AddRequest />} />
                <Route path="/newUser" element={<NewUser />} />

                <Route path="/viewTechnicians" element={<ViewTechnicians />} />
                <Route
                  path="/viewUserManagement"
                  element={<ViewUserManagement />}
                />
              </Routes>
            </div>
          ) : (
            <div>
              <Routes>
                <Route path="/" element={<LoginScreen />} />
              </Routes>
            </div>
          )}
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};

export default Navigation;
