import React from "react";
import ViewUser from "../../common/components/usermanagementcomp/ViewUser";

const ViewUserManagement = () => {
  return (
    <>
      <section className="overviewMainSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <ViewUser />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewUserManagement;
