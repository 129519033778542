import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "../common/components/inputfield/InputField";
import addIcon from "../common/assets/images/add_circle.svg";
import wrong from "../common/assets/images/wrong.svg";
import axios from "axios";
import { useMutation } from "react-query";
import { Navigate, useLocation } from "react-router";

export default function AddRequest() {
  const { control, handleSubmit, setValue, reset, watch } = useForm();
  const [file, setFile] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const { state } = useLocation();
  console.log("state", state);

  const handleImageInputChange = (event) => {
    console.log("event", event.target.files[0]);

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(",") + 1
        );
        setBase64Image(base64LetterRemove);
      };
      reader.onerror = (error) => {
        console.error(
          "Error occurred while converting image to base64:",
          error
        );
      };
    }
  };

  useEffect(() => {
    if (state) {
      setBase64Image(state?.photo);
      setValue("firstName", state?.firstName);
      setValue("lastName", state?.lastName);
      setValue("address", state?.address);
      setValue("mobileNo", state?.mobileNo);
      setValue("alternateNo", state?.alternateNo);
      setValue("product", state?.product);
      setValue("issue", state?.issue);
      // setValue('photoFile',state?.photo)
      setValue("selectSlot", state?.selectSlot);
      setValue("totalAmt", state?.totalAmount);
      setValue("actualAmt", state?.actualAmount);
      setValue("netAmt", state?.netAmount);
      setValue("technicianShare", state?.technicianShare);
      setValue("ownerShare", state?.ownerShare);
    }
  }, []);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/requests`,
          // "https://api.2kvirtualworld.com/CRM/technicians",
          postData
        );
        alert("successfully added.");
        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);

        throw error;
      }
    }
  );

  const UpdateFormValue = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/requests/${state?.id}`,
        postData
      ),
    {
      onSuccess: (data) => {
        alert("successfully updated.");
        // Navigate("/viewUserManagement");
      },
      onError: (error) => {
        // alert(error)
        console("Mutation failed:", error);
      },
    }
  );

  const Submit = (data) => {
    console.log("data", data);
    const formValue = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      address: data?.address,
      mobileNo: data?.mobileNo,
      alternateNo: data?.alternateNo,
      modelName: data?.modalName,
      product: data?.product,
      issue: data?.issue,
      photo: base64Image,
      selectSlot: data?.selectSlot,
      totalAmount: data?.totalAmt,
      actualAmount: data?.actualAmt,
      netAmount: data?.netAmt,
      technicianShare: data?.technicianShare,
      ownerShare: data?.ownerShare,
    };
    if (state && state?.id) {
      UpdateFormValue.mutate(formValue);
    } else {
      mutate(formValue);
    }
    // handleImageInputChange(data?.aadharFile);
  };
  return (
    <div className="w-100 p-3 px-4 ">
      <div
        className="row bg-white px-3 pb-4"
        style={{ height: "100vh", borderRadius: "10px", overflow: "scroll" }}
      >
        <header className="fs-2 fw-bold p-2 ps-4 " style={{ height: "10%" }}>
          ADD REQUEST
        </header>
        <section>
          <form onSubmit={handleSubmit(Submit)}>
            <div style={{}}>
              <div className="addUser_ticket_label">
                <h4 className="fw-bold"> Customer Details</h4>
                <div className=" col-12 mb-2 d-flex ">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      First Name
                    </label>
                    <Controller
                      control={control}
                      name="firstName"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Last Name
                    </label>
                    <Controller
                      control={control}
                      name="lastName"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 ">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Address
                    </label>
                    <Controller
                      control={control}
                      name="address"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" col-9 --addUser_companyName_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className=" col-12 mb-2 d-flex">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Mobile No
                    </label>
                    <Controller
                      control={control}
                      name="mobileNo"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Alternate No
                    </label>
                    <Controller
                      control={control}
                      name="alternateNo"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" --addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
              </div>

              <div className="">
                <h4 className="fw-bold"> Product Details</h4>
                {/* Product Details */}
                <div className=" col-12 mb-2 d-flex ">
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Product
                    </label>
                    <Controller
                      control={control}
                      name="product"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className="col-6 ">
                    <label className="col-5 addUser_ticket_label">
                      Model Name
                    </label>
                    <Controller
                      control={control}
                      name="modalName"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 ">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Issue
                    </label>
                    <Controller
                      control={control}
                      name="issue"
                      render={(field) => (
                        <InputField
                          // style = {{margin}}
                          addUserInputCss=" col-9 --addUser_companyName_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <div className="d-flex">
                    <label
                      style={{ width: "20%" }}
                      className="addUser_ticket_label"
                    >
                      Photo
                    </label>

                    <label
                      for="photoImg"
                      className="col-3 ms-2 aadharFileInput text-center d-flex justify-content-center align-items-center "
                    >
                      UPLOAD A FILE HERE{" "}
                      <img className="h-75" src={addIcon} alt="" />
                    </label>
                    <img
                      style={{ height: "30px", marginLeft: "10px" }}
                      alt=""
                      src={`data:image/jpeg;base64,${base64Image}`}
                    />
                    {base64Image && (
                      <img
                        onClick={() => {
                          setBase64Image("");
                          // removeKey("photoFile");
                        }}
                        className=" ps-2 h-75"
                        src={wrong}
                        alt=""
                      />
                    )}
                    <Controller
                      control={control}
                      name="photoFile"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          // value={value}
                          className=" ms-2 "
                          type="file"
                          // name="uploadfile"
                          id="photoImg"
                          style={{ display: "none" }}
                          onChange={(e) => onChange(handleImageInputChange(e))}
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12">
                <h4 className="fw-bold"> Payment and Slot Details</h4>
                {/* Product Details */}
                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Select Slot
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="selectSlot"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Total Amount
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="totalAmt"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Actual Amount
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="actualAmt"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Net Amount
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="netAmt"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Technician Share
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="technicianShare"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-between">
                  <label
                    style={{ width: "20%" }}
                    className=" addUser_ticket_label"
                  >
                    Owner Share
                  </label>
                  <div className="col-6 me-5 d-flex justify-content-end">
                    <Controller
                      control={control}
                      name="ownerShare"
                      render={(field) => (
                        <InputField
                          addUserInputCss="--addUser_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 addUser_submitBtn_field ">
                <button className=" col-5 addUser_submitBtn" type="submit">
                  {" "}
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}
